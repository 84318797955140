/* eslint-disable complexity */
import cn from 'classnames';

import {twMerge} from '@/stylesheets/twMerge';
import Section from '@/components/brochureV2/primitive/Section/Section';
import type {ButtonProps} from '@/components/base/modules/ButtonGroup/ButtonGroup';
import ButtonGroup from '@/components/base/modules/ButtonGroup/ButtonGroup';
import type {HeroProps} from '@/components/base/sections/Hero/types';
import {ColorSchemeEnum} from '@/enums';
import {heroBackgroundByTheme} from '@/stylesheets/brochureV2/colorSchemes';
import useSignup, {useSignupParser} from '@/hooks/useSignup';
import Kicker from '@/components/base/modules/HeadingGroup/Kicker';
import Typography from '@/components/base/elements/Typography/Typography';
import Spacer from '@/components/brochureV2/layout/Spacer/Spacer';
import HeroCard from '@/components/pos/primitive/HeroCard/HeroCard';
import type {BlockProps} from '@/components/brochureV2/primitive/Block/types';
import Button from '@/components/base/elements/Button/Button';
import type {LinkComponent} from '@/types';
import type {Mode} from '@/components/base/types';
import type {IncentiveDetails} from '@/pages/shopify.com/($locale)/pos/components/Hero/PosIncentivesPromoBanner';
import PosIncentivesPromoBanner from '@/pages/shopify.com/($locale)/pos/components/Hero/PosIncentivesPromoBanner';
import {useRetailUnifiedFunnel} from '@/hooks/experiments/useRetailUnifiedFunnel';
import {useTranslations} from '@/hooks/useTranslations';

interface PosHeroFullBleedProps extends HeroProps {
  blocks?: BlockProps[];
  kickerClassName?: string;
  showSignup?: boolean;
  mobileOverlayClassName?: string;
  hasSecondaryNav?: boolean;
  headingClass?: string;
  subheadingClass?: string;
  bleedBehindHeader?: boolean;
  disclaimerHtml?: string;
  incentiveDetails?: IncentiveDetails;
  showCardReaderDiscountIncentive?: boolean;
}

export default function PosHeroFullBleed({
  className,
  kickerClassName,
  mobileOverlayClassName,
  colorScheme,
  hasSecondaryNav = false,
  headingHtml,
  headingClass,
  subheadingClass,
  images,
  kicker,
  signup: signupData,
  showSignup = true,
  link,
  secondaryLink,
  subheadHtml,
  blocks,
  bleedBehindHeader,
  disclaimerHtml,
  incentiveDetails,
  showCardReaderDiscountIncentive,
  ...props
}: PosHeroFullBleedProps & {
  link: LinkComponent;
  secondaryLink: LinkComponent;
}) {
  const {t} = useTranslations();
  const {isRetailUnifiedFunnelExperiment} = useRetailUnifiedFunnel();
  const {signupUrl} = useSignup();
  const signup = useSignupParser(signupData);
  const scheme = colorScheme || ColorSchemeEnum.Light;
  const bannerMode =
    scheme === ColorSchemeEnum.Light ? colorScheme : ColorSchemeEnum.Dark;
  const buttonsArray = [];
  const bleedBehind = bleedBehindHeader || false;

  if (showSignup) {
    if (signup) {
      buttonsArray.push({
        href: signupUrl,
        text: signup.buttonText,
      } as ButtonProps);
    }

    if (secondaryLink) {
      buttonsArray.push({
        href: secondaryLink.url,
        text: secondaryLink.text,
        intent: 'secondary',
      } as ButtonProps);
    }
  }

  const conversionCta = isRetailUnifiedFunnelExperiment
    ? [{href: '#contact-sales', text: t('pos:components.contactSalesCta')}]
    : buttonsArray;

  const containerClass = blocks ? '-mb-20' : '';
  const hasImage = images && images.length > 0;
  return (
    <Section
      className={twMerge(
        containerClass,
        heroBackgroundByTheme[scheme],
        className,
      )}
      {...props}
    >
      <div
        className={twMerge(
          cn(
            'bg-bottom bg-no-repeat bg-[length:900px_auto] md:bg-[length:1800px_auto]',
            {
              'sm:-mt-hero-top': hasSecondaryNav && !bleedBehind,
            },
          ),
        )}
        style={{
          backgroundImage: `url('${hasImage ? images[0].srcSet : ''}')`,
        }}
      >
        {hasImage && <span className="sr-only">{images[0].alt}</span>}
        <div
          className={twMerge(
            'bg-pos-hardware-hero from-transparent to-[#ddbd8e] md:to-transparent',
            mobileOverlayClassName,
          )}
        >
          <div
            className={cn('container mx-auto text-center', {
              'pt-hero-with-subnav-mobile': bleedBehind,
            })}
          >
            <Spacer mobile="40" desktop="80" />
            <PosIncentivesPromoBanner
              mode={bannerMode as Mode}
              className="text-center self-center pb-8"
              incentiveDetails={incentiveDetails}
              showCardReaderDiscountIncentive={showCardReaderDiscountIncentive}
            />
            {kicker && (
              <>
                <Kicker
                  as="h1"
                  className={twMerge('font-bold text-lime-70', kickerClassName)}
                  size="lg"
                >
                  {kicker}
                </Kicker>
                <Spacer size="xs" />
              </>
            )}
            {headingHtml && (
              <>
                <Typography
                  size="dsp"
                  as="h2"
                  className={twMerge('max-w-prose mx-auto', headingClass)}
                >
                  {headingHtml}
                </Typography>
                <Spacer size="sm" />
              </>
            )}
            {subheadHtml && (
              <>
                <Typography
                  size="body-lg"
                  as="p"
                  className={twMerge('max-w-prose mx-auto', subheadingClass)}
                >
                  {subheadHtml}
                </Typography>
                <Spacer mobile="40" desktop="24" />
              </>
            )}
            {signup && showSignup && (
              <ButtonGroup
                center={true}
                buttons={conversionCta}
                disclaimerHtml={signup.disclaimerHtml}
              />
            )}
            {link && (
              <div className="text-center">
                <Button
                  key={link.url}
                  href={link.url as string}
                  {...link}
                  intent="primary"
                >
                  {link.text}
                </Button>

                {disclaimerHtml && (
                  <Typography size="body-sm" as="p" className="mt-4">
                    {disclaimerHtml}
                  </Typography>
                )}
              </div>
            )}
            <Spacer desktop="320" mobile="320" />
            <Spacer desktop="320" mobile="80" />
            <Spacer desktop="80" />
          </div>
        </div>
      </div>
      {blocks && <HeroCard blocks={blocks} />}
    </Section>
  );
}
